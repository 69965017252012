/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from "./router"



import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Dock from 'primevue/dock';
import Galleria from 'primevue/galleria';

import InputText from 'primevue/inputtext';
import Menubar from 'primevue/menubar';
import MegaMenu from 'primevue/megamenu';

import Panel from 'primevue/panel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TabMenu from 'primevue/tabmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
// import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'


const app = createApp(App);
  app.use(PrimeVue, {ripple: true});
  app.use(router);
  app.use(ToastService);


  app.component('Accordion', Accordion);
  app.component('AccordionTab', AccordionTab);
  app.component('Button', Button);
  app.component('Calendar', Calendar);
  app.component('Card', Card);
  app.component('Dialog', Dialog);
  app.component('Dock', Dock);
  app.component('Galleria', Galleria);
  app.component('Panel', Panel);
  app.component('InputText', InputText);
  app.component('Menubar', Menubar);
  app.component('MegaMenu', MegaMenu);
  app.component('TabMenu', TabMenu);
  app.component('TabView', TabView);
  app.component('TabPanel', TabPanel);
  app.component('Textarea', Textarea);
  app.component('Toast', Toast);

  



  app.mount('#app')
