import { createRouter, createWebHistory } from "vue-router"
import pricetor from "@/components/pricetor.vue";

const routes = [
    { path : "/", name: "pricetor", component : pricetor },
]        

const router = createRouter({
    history : createWebHistory(),
    routes : routes
})

export default router;