<template>
  <Pricetor/>
</template>

<script>
import Pricetor from './components/pricetor.vue'

export default {
  name: 'App',
  components: {
    Pricetor
  }
}
</script>



<style>
@font-face {
    font-family: "font1";
    src: url('~@/assets/fonts/Shrikhand-Regular.ttf');
}

@font-face {
    font-family: "font2";
    src: url('~@/assets/fonts/AbrilFatface-Regular.ttf');
}

@font-face {
    font-family: "font3";
    src: url('~@/assets/fonts/AlumniSansPinstripe-Regular.ttf');
}

@font-face {
    font-family: "font4";
    src: url('~@/assets/fonts/Pacifico-Regular.ttf');
}

html,body {
  margin: 0;
  /* width: 100%;
  background-image: url('~@/assets/team6.jpg');
  background-repeat:no-repeat;
  background-size:cover;  */

  /* background: rgb(193,209,232); */
  /* background: linear-gradient(225deg, rgba(193,209,232,1) 1%, rgba(25,27,48,1) 100%); */
} 

</style>
