<template> 
      
  <div class="fon">
    <Menubar :model="menuitems" class="mymenubar"></Menubar>

    <div class="flex flex align-items-center justify-content-center" style="margin-top: 300px;">
      <Button 
        class="text-xl font-bold bg-cyan-400 border-2 shadow-3 hover:shadow-8"
        rounded
        @click="contact_dialog = true"
      >Получить бесплатную консультацию</Button>
    </div> 

    <div class="flex inline-block justify-content-end" >

      <img 
        style="margin: 0 10px 0 10px; cursor: pointer;" 
        alt="logo" 
        :src="require('../assets/telegram2.png')" 
        height="50"
        @click="telegram_link()"
      />

      <img 
        style="margin: 0 10px 0px 0; cursor: pointer;" 
        alt="logo" 
        :src="require('../assets/viber2.png')" 
        height="50"
        @click="viber_link()"
      />

      <img 
        style="margin: 0 10px 0px 0; cursor: pointer;" 
        alt="logo" 
        :src="require('../assets/instagram.png')" 
        height="50"
        @click="inst_link()"
      />

    </div> 
  </div> 


        
  <p class="head">Создание сайтов, web-приложений, автоматизация интернет магазинов</p>      

  <div class="missia">
      
      <span style="font-size: 24px; font-weight: 800;">Миссия</span>
      <span> – эффективно решать задачи в области автоматизации бизнеса для наших клиентов, расти, развиваться, действовать в гармонии с обществом и окружающей средой.</span>
      <p>Для разработки используются самые современные технологии в области разработки програмного обеспечения:</p>
    <div>
      <img style="margin: 0 10px 0 10px;" alt="logo" :src="require('../assets/python2-round.png')" height="200"  />
      <img style="margin: 0 10px 0 10px;" alt="logo" :src="require('../assets/django-round.png')" height="200"  />
      <img style="margin: 0 10px 0 10px;" alt="logo" :src="require('../assets/vue-round.png')" height="200"  />
      <img style="margin: 0 10px 0 10px;" alt="logo" :src="require('../assets/postgre-round.png')" height="200"  />
    </div>
  </div> 
  
  <p class="head">Услуги</p>

  <Accordion class="uslugi" :multiple="true" :activeIndex="0">

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Создание сайтов, WEB разработка</span>
          </template>
          <p style="font-family: century gothic;">
            Полный комплекс услуг по web-разработке от создания и согласования дизайна 
            сайта до размещения его на сервере с последующей поддержкой.
            Помогу создать уникальный дизайн для Вашего сайта, в том числе с использованием 
            современных и наиболее эффективных инструментов Web-разработки, 
            интегрировать необходимые метрики для сбора данных и последующего анализа 
            эффективности работы сайта, 
            а также провести необходимые SEO-настройки для продвижения сайта в поисковых системах.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Сбор и обработка данных</span>
          </template>
          <p style="font-family: century gothic;">
            Предлагаю услуги по разработке автоматизированных систем сбора и обработки данных с 
            интересующих Вас веб-сайтов с последующим их хранением в базе данных. К примеру, Вы 
            хотите анализировать комментарии и отзывы пользователей, оценки продукта на 
            маркетплейсах или любую другую информацию, которую можно получить только посредством 
            сбора с сайта, тогда это решение для Вас.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Визуализация данных</span>
          </template>
          <p style="font-family: century gothic;">
            Визуализация данных в Power BI
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Разработка приложения для Android</span>
          </template>
          <p style="font-family: century gothic;">
            Создание мобильных приложений для Android.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Разработка приложения для iOS</span>
          </template>
          <p style="font-family: century gothic;">
            Создание мобильных приложений для iOS.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Разработка CRM системы</span>
          </template>
          <p style="font-family: century gothic;">
            Создание собственной CRM системы, учитывающей все особенности Вашего бизнеса.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Разработка SCADA системы</span>
          </template>
          <p style="font-family: century gothic;">
            Создание SCADA системы с собственной базой данных.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Чат-боты и умные помощники</span>
          </template>
          <p style="font-family: century gothic;">
            Чат-боты и умные помощники помогут Вам оптимизировать процесс общения с клиентами 
            и сделать его более интерактивным. Наиболее популярные мессенджеры, такие как 
            telegram, whatsapp, facebook messenger, имеют возможность интеграции ботов. Все это 
            делает ответы на задаваемые вопросы моментальными, позволяет интегрировать оплату, 
            размещение заявок, заказов, подключив бота к системе управления заказами, используемой 
            Вашей компанией.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Пакет "Бизнес с нуля"</span>
          </template>
          <p style="font-family: century gothic;">
            Данный продукт представляет собой совокупность сервисов: анализ рынка товаров и 
            услуг, определение потенциальной результативности бизнеса, разработка маркетинговой 
            стратегии, веб-сайта или интернет-магазина с рекомендательной системой и 
            автоматизированным механизмом скидок, а также последующим сопровождением и анализом 
            эффективности бизнеса.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Автоматизация торговых алгоритмов</span>
          </template>
          <p style="font-family: century gothic;">
            Предлагаю разработку автоматизированных систем торговли на различных криптовалютных 
            биржах с использованием требуемых инструментов риск-менеджмента.
            Проведите тестирование своей стратегии, ищите пути ее оптимизации и запускайте на 
            удаленном сервере, чтобы извлекать из рынка прибыль по вашей стратегии 24/7.
          </p>
      </AccordionTab>

      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Автоматизированный голосовой консультант</span>
          </template>
          <p style="font-family: century gothic;">
            Один из популярнейших способов снижения нагрузки на Ваш call-центр. Поможет 
            дифференцировать запросы клиентов по соответствующим менеджерам, ответить на 
            некоторые вопросы клиентов автоматически, а также повысить общую эффективность 
            работы отдела поддержки.
          </p>
      </AccordionTab>
      
      <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Сканнер цен</span>
          </template>
          <p style="font-family: century gothic;">
            Инструмент, позволяющий отслеживать рыночные цены товаров и услуг, предлагаемых 
            конкурентами. Price Scanner визуализирует динамику рыночных цен интересующих Вас 
            товаров и услуг в разрезе продавцов, а также предлагает различную статистику: 
            анализ цен, динамики цен производителей, предоставленных скидок, разброса цен и 
            множества других показателей. С помощью Price Scanner Вы сможете оптимизировать 
            ценовую политику исходя из актуальных рыночных цен и действий конкурентов, 
            разработать эффективную систему скидок и всегда иметь понимание сложившейся 
            рыночной конъюнктуры
          </p>
      </AccordionTab>

      <!-- <AccordionTab>
          <template #header>
          <img alt="price_scaner" :src="require('../assets/logo_ready-round.png')" height="30"/>
          <span class="accord">Анализ эффективности рекламы</span>
          </template>
          <p style="font-family: century gothic;">
            Проведение анализа эффективности используемой рекламы на различных площадках. 
            Это позволит определить наиболее эффективные способы рекламы, таргетирования, 
            привлечения клиентов и аудиторию с наибольшей покупательной способностью, а также 
            выявить, насколько результативны для бизнеса изменения на сайте, торговом объекте, 
            в подходе менеджеров к клиентам и других аспектах ведения бизнеса.
          </p>
      </AccordionTab> -->
  </Accordion>

  <p class="head">Портфолио</p>

  <Galleria
      :value="galleria_images" 
      :numVisible="4" 
      thumbnailsPosition="left" 
      containerClass="galleria"
      :showItemNavigators=true
      :showThumbnailNavigators=false
      :circular=true
      verticalThumbnailViewPortHeight="650px"
  >
      <template #item="slotProps">
        <img 
          :src="slotProps.item" 
          :alt="slotProps.item" 
          style="" 
          class="big_image"
        />
      </template>
      <template #thumbnail="slotProps">
        <img 
          :src="slotProps.item" 
          :alt="slotProps.item" 
          style="" 
          class="thumbnail_image"
        />
      </template>
  </Galleria>


  <p class="head">Контакты</p>

  <p class="flex flex align-items-center justify-content-center"
     style="font-size: 44px; margin: 0;"
  >
    <img 
        :src="require('@/assets/A1.png')" 
        :alt="11" 
        class="kontakt_image"
    />
    <img 
          :src="require('@/assets/viber.png')" 
          :alt="11" 
          class="kontakt_image mr-2"
    />
    <span 
      style="font-family: century gothic; font-size: 24px; cursor: pointer;"
      @click="viber_link()"
    >
      +375 29 341-46-32
    </span>
  </p>
  
  <p class="flex flex align-items-center justify-content-center"
     style="font-size: 44px; margin: 5px;"
  >
    <img 
        :src="require('@/assets/MTS.png')" 
        :alt="11" 
        class="kontakt_image mr-2"
    />
    <span style="font-family: century gothic; font-size: 24px;">
      +375 29 7771-772
    </span>
  </p>

  <p 
    class="flex flex align-items-center justify-content-center"
    style="font-size: 44px; margin: 5px;"
  >
    <img 
        :src="require('@/assets/telegram.png')" 
        :alt="11" 
        class="kontakt_image"
        style="cursor: pointer"
        @click="telegram_link()"
    />
    <span 
      style="font-family: century gothic; font-size: 24px; cursor: pointer;"
      @click="telegram_link()"
    >
    @Vitosons
    </span>
  </p>

  <p 
    class="flex flex align-items-center justify-content-center"
    style="font-size: 44px; margin: 5px;"
  >
    <img 
        :src="require('@/assets/instagram.png')" 
        :alt="11" 
        class="kontakt_image ml-2 mr-2"
        style="cursor: pointer"
        @click="inst_link()"
    />
    <span 
      style="font-family: century gothic; font-size: 24px; cursor: pointer;"
      @click="inst_link()"
    >
    Pricetor
    </span>
  </p>

  
      
  <Toast /> 
  <Dialog 
    v-model:visible="contact_dialog" 
    header="Отправка сообщения"
    class="w-5"
  >  
        <div class="p-inputgroup mt-4">
          <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
          </span>
          <span class="p-float-label">
            <InputText  
              v-model="v$.name.$model" 
              class="w-8"
            />
            <label for="name">Имя*</label>
          </span>
        </div>
        
        <div class="p-inputgroup mt-4">
          <span class="p-inputgroup-addon">
              <i class="pi pi-book"></i>
          </span>
          <span class="p-float-label">
              <InputText  
                v-model="v$.subject.$model" 
                class="w-8"
              />
              <label for="subject">Тема*</label>
          </span>
        </div>

        <div class="p-inputgroup mt-4">
            <span class="p-inputgroup-addon">
                <i class="pi pi-at"></i>
            </span>
            <div class="p-float-label">
                <InputText 
                    id="email" 
                    v-model="v$.email.$model" 
                    :class="{'p-invalid':v$.email.$invalid && submitted}" 
                    aria-describedby="email-error"
                />
                <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
            </div>
        </div>
        <span v-if="v$.email.$error && submitted">
            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
            <small class="p-error">{{error.$message}}</small>
            </span>
        </span>
        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>

        <div class="p-inputgroup mt-4">
          <span class="p-inputgroup-addon">
              <i class="pi pi-phone"></i>
          </span>
          <span class="p-float-label">
              <InputText  
                v-model="v$.phone.$model" 
                class="w-8"
              />
              <label for="phone">Телефон*</label>
          </span>
        </div>

        <div class="p-inputgroup mt-4">
          <span class="p-inputgroup-addon">
              <i class="pi pi-book"></i>
          </span> 
          <span class="p-float-label">
              <Textarea 
                v-model="v$.message.$model" 
                rows="5" 
                cols="50" 
                class="w-8"
              />
              <label for="message">Сообщение*</label>
          </span>
        </div>

        <template #footer>
          <div 
            v-if="name && subject && email && phone && message" 
            class="flex flex align-items-center justify-content-center"
          >
            <Button 
              class="text-xl font-bold bg-cyan-400 border-2 shadow-3 hover:shadow-8"
              rounded
              @click="send_email(name, subject, email, phone, message)"
            >Отправить сообщение
            <i class="pi pi-send ml-3"></i>
            </Button>
          </div> 
          <div 
            v-else 
            class="flex flex align-items-center justify-content-center"
          >
            <Button 
              class="text-xl font-bold bg-cyan-400 border-2 shadow-3 hover:shadow-8"
              rounded
              disabled
            >Отправить сообщение
            <i class="pi pi-send ml-3"></i>
            </Button>
          </div> 

        </template>
  </Dialog>

  
</template>



<script>
import axios from 'axios'
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength } from '@vuelidate/validators'


export default {
  name: 'My-site', 
  setup: () => ({ v$: useVuelidate() }),
  data() {
        return {
          toast: useToast(),
          contact_dialog: false,
          name: null,
          subject: null, 
          email: null, 
          phone: null, 
          message: null,
          submitted: false,
          galleria_images: [
             require('@/assets/galleria/chibbis1.png'),
             require('@/assets/galleria/stud_print1.png'),
             require('@/assets/galleria/pricetor3.png'),
             require('@/assets/galleria/pricetor2.png'),
             require('@/assets/galleria/bork1.png'),
             require('@/assets/galleria/elena1.png'),
          ],
          menuitems: [ 
                {
                  label: 'Главная', 
                  icon: '', 
                  // command: (event) => {
                  command: () => {
                    window.scrollTo({ top: 1, behavior: 'smooth' }); 
                  },
                },
                {
                  label: 'Услуги', 
                  icon: 'pi pi-align-center', 
                  command: () => {
                    window.scrollTo({ top: 1100, behavior: 'smooth' }); 
                  },
                },
                {
                  label: 'Портфолио', 
                  icon: 'pi pi-align-center', 
                  command: () => {
                    window.scrollTo({ top: 2000, behavior: 'smooth' }); 
                  },
                },
                {
                  label: 'Контакты', 
                  icon: 'pi pi-phone', 
                  command: () => {
                    window.scrollTo({ top: 2500, behavior: 'smooth' }); 
                  },
                },
          ],
        };
  },
  validations() {
        return {
            name: {
                required,
                minLength: minLength(1)
            },
            subject: {
                required,
                minLength: minLength(1)
            },
            email: {
                required,
                email
            },
            phone: {
                required,
                minLength: minLength(1)
            },
            message: {
                required,
                minLength: minLength(1)
            },
        }
  },
  methods: {
    telegram_link(){
      window.location.href = 'https://t.me/Vitosons';
    },
    viber_link(){
      window.location.href = 'viber://chat?number=+375293414632';
    },
    inst_link(){
      window.location.href = 'https://www.instagram.com/pricetor';
    },
    send_email(name, subject, email, phone, message) {
        if ((this.v$.email.$invalid == false) && 
            (this.v$.name.$invalid == false)) {   
                this.contact_dialog = false
                axios.post ('https://api.staticforms.xyz/submit', 
                  { 
                    accessKey: '8c8caa24-2e26-49d9-a5e6-ecf08e8faf71',
                    name: name,
                    subject: subject,
                    email: email,
                    phone: phone,
                    message: message
                  },
                  { headers: 
                    {
                      "Content-type": "application/json",
                      // "Authorization": `Bearer ${state.token.access}`
                    }
                  } 
                  // kvk_vit@tut.by Access Key: 8c8caa24-2e26-49d9-a5e6-ecf08e8faf71
                  // mysite.post.sender@gmail.com Access Key: 172e49e3-5a30-4b25-8877-8647abfe1280       
                  )
                  .then(() => {
                      this.name = null;
                      this.subject = null;
                      this.email = null;
                      this.phone = null;
                      this.message = null;
                      this.toast.add({ 
                        severity: 'info', 
                        summary: 'Info', 
                        detail: 'Сообщение отправлено !', 
                        life: 3000 
                      });

                  }) 
                  .catch((error) => {
                      console.log(error)
                      this.toast.add({ 
                        severity: 'info', 
                        summary: 'Info', 
                        detail: 'Сообщение не отправлено !', 
                        life: 3000 
                      });
                      return error;
                  })             
        }
        else {
            this.submitted = true
        }
      },
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.fon {
  height: 500px;
  width: 100%;
  background-image: url('~@/assets/team6.jpg');
  background-repeat:no-repeat;
  background-size:cover;
}

::v-deep(.mymenubar) {
  background: transparent !important; 
  border: 0 !important; // убирает контур блока меню
  height: 100px !important; // высота блока меню

  .p-menubar-root-list{
    margin: 0 auto 0 auto !important;  // рабочий отступ
    padding:  0 0 0 500px !important;  // рабочий отступ
  } 
  .p-menuitem-text{
    color:rgb(0, 0, 0) !important;
    font-family: century gothic !important;
    font-size: 24px !important;
  } 
}

::v-deep(.p-dialog) {
  background-color: rgb(255, 0, 0) !important;
  .p-dialog-titlebar{
    background-color: rgb(255, 0, 0) !important;

  }
}


.missia {
  text-align: center;
  // font-family: "TIMES NEW ROMAN";
  // font-family: "font4";
  // font-style: italic;
  // font-weight: 800; 
  font-family: century gothic;
  font-size: 24px;
  margin: 0 100px 0 100px;
}

.head {
  color:#000000;
  text-align: center;
  font-family: century gothic;
  font-size: 44px;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}  
  
.uslugi {
  text-align: center;
  font-family: "TIMES NEW ROMAN";
  margin: 0 150px 0 150px; 
}

.accord {
  margin: 0 0 0 50px; 
  color: #000000;
  font-size: 24px; 
  font-family: century gothic;

}

.galleria {
  // background-color: aqua;
  margin:10px auto 0px;
  width: 950px;
  height: 700px;
  max-width: 950px;
  max-height: 700px;
}
.big_image {
  width: 780px;
  max-width: 780px;
  max-height: 600px;
  object-fit: scale-down;
  border-radius: 10px;

}
.thumbnail_image {
  width: 150px;
  max-height: 150px;
  object-fit: scale-down;
  border-radius: 10px;
  margin: 0 10px 0 0;
}

.kontakt_image {
  max-height: 30px;
  object-fit: scale-down;
}


::v-deep(.p-galleria-thumbnail-container){
  background-color: rgb(255, 255, 255) !important;
}



</style>
